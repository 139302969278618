import React, { useState, useCallback } from 'react';
import { apiClient } from '../services/api';
import { useAuth } from 'react-oidc-context';
import { AxiosError } from 'axios';

interface SaveCodeParams {
  code: string;
  existingDraff?: {
    username: string;
    title: string;
  };
}

interface SaveCodeReturn {
  loading: boolean;
  error: { toast: string } | null;
  saveCode: (
    params: SaveCodeParams,
  ) => Promise<{ username: string; draffName: string }>;
}

export const useSaveCode = (): SaveCodeReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ toast: string } | null>(null);
  const auth = useAuth();
  /**
   * Saves a draff to the database. If existingDraff is undefined, a new draff is created,
   * Otherwise overwrites the existing draff based on username and title.
   * @param code - The code to save.
   * @param existingDraff - The existing draff to update.
   * @returns The username and draff name of the saved draff.
   */
  const saveCode = useCallback<SaveCodeReturn['saveCode']>(
    async ({ code, existingDraff }) => {
      setLoading(true);
      setError(null);

      try {
        if (existingDraff) {
          // Update existing draff
          const response = await apiClient.put(
            `/v1/draffs/${existingDraff.username}/${existingDraff.title}`,
            { code },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
              },
            },
          );

          return {
            username: response.data.username,
            draffName: response.data.title,
          };
        } else {
          // Create new draff
          const response = await apiClient.post(
            '/v1/draffs',
            { code },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
              },
            },
          );

          return {
            username: `${response.data.username}`,
            draffName: response.data.title,
          };
        }
      } catch (err) {
        console.error('Error saving code:', (err as any)?.response);
        if (err instanceof AxiosError && err.response?.status === 402) {
          setError({ toast: err.response?.data.message });
        } else {
          setError({ toast: 'Failed to save code' });
        }
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [auth.user],
  );

  return { loading, error, saveCode };
};
