import axios, { AxiosInstance } from 'axios';
import { useAuth } from 'react-oidc-context';
import React from 'react';

class AuthenticatedClient {
  private client: AxiosInstance;
  private baseURL: string =
    process.env.REACT_APP_API_URL || 'http://localhost:3001';

  constructor() {
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // Configure the client with the current access token
  public configure(accessToken: string | null) {
    if (accessToken) {
      this.client.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${accessToken}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    }
  }

  async get(url: string) {
    return this.client.get(url);
  }

  async post(url: string, data?: any) {
    return this.client.post(url, data);
  }

  async put(url: string, data?: any) {
    return this.client.put(url, data);
  }

  async patch(url: string, data?: any) {
    return this.client.patch(url, data);
  }

  async delete(url: string) {
    return this.client.delete(url);
  }
}

// Create a singleton instance
export const authClient = new AuthenticatedClient();

// Hook to use the authenticated client
export const useAuthClient = () => {
  const auth = useAuth();

  // Configure the client with the current access token whenever auth state changes
  React.useEffect(() => {
    if (auth.isAuthenticated && auth.user?.access_token) {
      authClient.configure(auth.user.access_token);
    } else {
      authClient.configure(null);
    }
  }, [auth.isAuthenticated, auth.user?.access_token]);

  return authClient;
};
