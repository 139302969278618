import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { apiClient } from '../services/api';

interface BackendUser {
  username: string;
  userId: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: {
    email?: string;
    username?: string;
    access_token?: string;
  } | null;
  backendUser: BackendUser | null;
  signin: () => Promise<void>;
  signout: () => Promise<void>;
  removeUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();
  const [backendUser, setBackendUser] = useState<BackendUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.user?.access_token) {
        try {
          const response = await apiClient.get('/v1/user/self', {
            headers: {
              Authorization: `Bearer ${auth.user.access_token}`,
            },
          });
          setBackendUser(response.data);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      } else {
        setBackendUser(null);
      }
      setIsLoading(false);
    };

    fetchUser();
  }, [auth.user?.access_token]);

  const value = {
    isAuthenticated: auth.isAuthenticated,
    isLoading,
    user: auth.user
      ? {
          ...auth.user.profile,
          access_token: auth.user.access_token,
        }
      : null,
    backendUser,
    signin: () => auth.signinRedirect(),
    signout: async () => {
      await auth.removeUser();
      localStorage.clear();
      sessionStorage.clear();
      await auth.signoutSilent();
      window.location.href = '/';
    },
    removeUser: () => auth.removeUser(),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
