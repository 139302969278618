import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Code,
} from '@chakra-ui/react';
import React from 'react';
import { FC } from 'react';
import { Button } from '../../ui/Button';
import { IoLeaf } from 'react-icons/io5';

interface DraffErrorProps {
  message?: string;
}

/** Generic error when we don't know what went wrong */
export const DraffError: FC<DraffErrorProps> = ({ message }) => {
  const onClick = () => (window.location.href = '/js/new');

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="404px"
      backgroundColor="yellow.100"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Gahh!
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        A fatal error occurred 💀 Sorry.
        <br />
        The error below might provide more info:
        <br />
        <Code
          display="block"
          p={4}
          my={2}
          borderRadius="md"
          bg="red.50"
          color="red.900"
          width="100%"
          border="2px"
          borderColor="yellow.600"
        >
          {message || 'An unexpected error occurred while loading this Draff.'}
        </Code>
        <br />
        <p>Perhaps start a new Draff?</p>
        <br />
        <Button colorScheme="teal" rightIcon={<IoLeaf />} onClick={onClick}>
          New Draff
        </Button>
      </AlertDescription>
    </Alert>
  );
};
