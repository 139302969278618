import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from '@chakra-ui/react';
import { apiClient } from '../../services/api';
import { Link as RouterLink } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import { useAuth } from 'react-oidc-context';
import { Navbar } from '../../ui/Navbar';

interface Draff {
  title: string;
  language: string;
  updatedAt: string;
  author: string;
}

export const Dashboard = () => {
  const auth = useAuth();
  const [draffs, setDraffs] = useState<Draff[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [draffToDelete, setDraffToDelete] = useState<Draff | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  useEffect(() => {
    const fetchDraffs = async () => {
      try {
        const response = await apiClient.get('/v1/draffs/user/self', {
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        });
        setDraffs(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch draffs:', err);
        setError('Failed to load draffs');
        setLoading(false);
      }
    };

    if (auth.user) {
      fetchDraffs();
    }
  }, [auth.user]);

  const handleDelete = async () => {
    if (!draffToDelete) return;

    try {
      await apiClient.delete(
        `/v1/draffs/@${draffToDelete.author}/${draffToDelete.title}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );
      setDraffs(draffs.filter((d) => d.title !== draffToDelete.title));
      toast({
        title: 'Draff deleted',
        description: `Successfully deleted "${draffToDelete.title}"`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        variant: 'subtle',
        containerStyle: {
          background: 'yellow.50',
        },
      });
    } catch (err) {
      console.error('Failed to delete draff:', err);
      toast({
        title: 'Error',
        description: 'Failed to delete draff',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
        variant: 'subtle',
        containerStyle: {
          background: 'yellow.50',
        },
      });
    } finally {
      setDraffToDelete(null);
    }
  };

  return (
    <Box minHeight="100vh" bgColor="yellow.50">
      <Navbar title="Dashboard" />

      {/* Content */}
      <Box p={8}>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Text fontSize="2xl" fontWeight="bold" color="orange.600">
            Your Draffs
          </Text>
          <Link as={RouterLink} to="/js/new">
            <Button
              bgColor="orange.600"
              color="white"
              _hover={{ bgColor: 'orange.700' }}
              size="sm"
              borderRadius="0"
              h="32px"
              fontSize="14px"
            >
              New Draff
            </Button>
          </Link>
        </Flex>

        {loading ? (
          <Flex justify="center" py={8}>
            <Spinner color="orange.600" size="xl" />
          </Flex>
        ) : error ? (
          <Text color="red.500">{error}</Text>
        ) : draffs.length === 0 ? (
          <Text color="gray.600">No draffs yet. Create your first one!</Text>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  color="orange.600"
                  width={{ base: 'auto', md: '300px' }}
                  pl={4}
                >
                  Title
                </Th>
                <Th color="orange.600" px={6}>
                  Language
                </Th>
                <Th color="orange.600" px={6}>
                  Last Updated
                </Th>
                <Th width="50px"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {draffs.map((draff) => (
                <Tr key={draff.title} _hover={{ bgColor: 'yellow.100' }}>
                  <Td pl={4}>
                    <Link
                      as={RouterLink}
                      to={`/d/@${draff.author}/${draff.title}`}
                      color="orange.600"
                      fontWeight="medium"
                      _hover={{ textDecoration: 'underline' }}
                      display="block"
                      minWidth={{ base: 'auto', md: '160px' }}
                    >
                      {draff.title}
                    </Link>
                  </Td>
                  <Td px={6}>{draff.language}</Td>
                  <Td px={6}>
                    {new Date(draff.updatedAt).toLocaleDateString()}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => setDraffToDelete(draff)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      <AlertDialog
        isOpen={draffToDelete !== null}
        leastDestructiveRef={cancelRef}
        onClose={() => setDraffToDelete(null)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="yellow.50">
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color="orange.600"
            >
              Delete Draff
            </AlertDialogHeader>

            <AlertDialogBody color="gray.700">
              Are you sure you want to delete "{draffToDelete?.title}"? This
              action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setDraffToDelete(null)}
                variant="ghost"
                color="gray.600"
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                borderRadius="0"
                h="32px"
                fontSize="14px"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
