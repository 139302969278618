import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useSaveCode } from '../api/useSaveCode';
import { useGetCode } from '../api/useGetCode';
import { useParams } from 'react-router-dom';
import { apiClient } from '../services/api';
import { useAuthContext } from './AuthContext';

interface DraffContextType {
  initialCode: string | null;
  error: string | null;
  /** Initial load state when fetching draff */
  isLoading: boolean;
  /** Saving state when saving draff */
  isSaving: boolean;
  username: string;
  title: string;
  saveDraff: (code: string, options?: { updateUrl?: boolean }) => Promise<void>;
  renameDraff: (newTitle: string) => Promise<void>;
}

const DraffContext = createContext<DraffContextType | null>(null);

export const useDraffContext = () => {
  const context = useContext(DraffContext);
  if (!context) {
    throw new Error('useDraffContext must be used within a DraffProvider');
  }
  return context;
};

interface DraffProviderProps {
  children: React.ReactNode;
}

const normalizeUsername = (username: string) => {
  if (username === 'dev/null') {
    return username;
  }
  return username.startsWith('@') ? username : `@${username}`;
};

export const DraffProvider: React.FC<DraffProviderProps> = ({ children }) => {
  const params = useParams();
  const { user } = useAuthContext();
  const [initialCode, setInitialCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [username, setUsername] = useState(params.username ?? 'dev/null');
  const [title, setTitle] = useState(params.title ?? 'untitled');
  const { saveCode } = useSaveCode();
  const {
    code,
    error: getCodeError,
    loading: getCodeLoading,
  } = useGetCode({
    username: params.username ?? 'dev/null',
    title: params.title ?? 'untitled',
  });

  useEffect(() => {
    if (!params.username || !params.title) {
      setIsLoading(false);
      return;
    }

    setUsername(params.username ?? 'dev/null');
    setTitle(params.title ?? 'untitled');
  }, [params.username, params.title]);

  useEffect(() => {
    if (!getCodeLoading) {
      setInitialCode(code);
      setError(getCodeError || null);
      setIsLoading(false);
    }
  }, [code, getCodeError, getCodeLoading]);

  const saveDraff = useCallback(
    async (
      code: string,
      { updateUrl = true }: { updateUrl?: boolean } = {},
    ) => {
      console.log('saving draff');
      if (isLoading || isSaving) {
        console.log('Cannot save Draff: already loading or saving');
        return;
      }
      try {
        setIsSaving(true);
        const existingDraff =
          username !== 'dev/null' ? { username, title } : undefined;
        console.log('existingDraff', existingDraff);
        const { username: newUsername, draffName: newTitle } = await saveCode({
          code,
          existingDraff,
        });
        setUsername(newUsername);
        setTitle(newTitle);
        if (updateUrl) {
          window.history.pushState(
            {},
            '',
            `/d/${normalizeUsername(newUsername)}/${newTitle}`,
          );
        }
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to save Draff!');
      } finally {
        setIsSaving(false);
      }
    },
    [username, title, isLoading, isSaving, saveCode],
  );

  const renameDraff = useCallback(
    async (newTitle: string) => {
      if (
        !user?.access_token ||
        username === 'dev/null' ||
        newTitle === title
      ) {
        return;
      }

      try {
        const response = await apiClient.patch(
          `/v1/draffs/${username}/${title}/rename`,
          { newTitle },
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          },
        );

        setTitle(response.data.title);
        window.history.pushState(
          { path: `/d/${normalizeUsername(username)}/${response.data.title}` },
          '',
          `/d/${normalizeUsername(username)}/${response.data.title}`,
        );
      } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to rename Draff!');
        throw err;
      }
    },
    [username, title, user?.access_token],
  );

  const value = {
    initialCode,
    error,
    isLoading,
    isSaving,
    username,
    title,
    saveDraff,
    renameDraff,
  };

  return (
    <DraffContext.Provider value={value}>{children}</DraffContext.Provider>
  );
};
