import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
export const AuthCallbackHandler = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.activeNavigator !== undefined) {
      return;
    }

    if (auth.isAuthenticated) {
      navigate('/');
    }
  }, [auth, navigate]);
  return <div>logging in...</div>;
};
