import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, Alert, AlertIcon } from '@chakra-ui/react';
import { apiClient } from '../../services/api';
import { API_CONFIG } from '../../config/api';
import { useAuth } from 'react-oidc-context';

export const Profile = () => {
  const auth = useAuth();
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.isAuthenticated) {
        return;
      }
      try {
        const response = await apiClient.get('/v1/user/self', {
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        });
        setUserData(response.data);
      } catch (err) {
        setError('Failed to load user data');
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [auth.user?.access_token, auth.isAuthenticated]);

  if (loading) {
    return (
      <Box p={4} display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box p={4}>
      <Text as="pre" whiteSpace="pre-wrap">
        {JSON.stringify(userData, null, 2)}
      </Text>
    </Box>
  );
};
