import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from 'react-router-dom';
import { Draff } from './views/Draff';
import { Profile } from './views/Profile/Profile';
import { extendBaseTheme, theme as chakraTheme } from '@chakra-ui/react';
import { AuthCallbackHandler } from './components/AuthCallbackHandler';
import { Dashboard } from './views/Dashboard/Dashboard';
import { Signin } from './views/Signin';
import { Settings } from './views/Settings/Settings';
import { AuthProvider } from './contexts/AuthContext';
import { DraffProvider } from './contexts/DraffContext';
import { useAuth } from 'react-oidc-context';

const { Button, Tabs, Input, Container, Card, Modal, Heading, Alert, Tag } =
  chakraTheme.components;

const theme = extendBaseTheme({
  components: {
    Button,
    Tabs,
    Input,
    Container,
    Card,
    Modal,
    Heading,
    Alert,
    Tag,
  },
});

const DraffWrapper = () => (
  <DraffProvider>
    <Draff />
  </DraffProvider>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/js/new" replace />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
  {
    path: 'signin',
    element: <Signin />,
  },
  {
    path: 'js/new',
    element: <DraffWrapper />,
  },
  {
    path: 'd/:username/:title',
    element: <DraffWrapper />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/callback',
    element: <AuthCallbackHandler />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
]);

function App() {
  const auth = useAuth();

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent, auth]);

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
