import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import { useAuthClient } from '../../services/authClient';

export const Signin = () => {
  const auth = useAuth();
  const authClient = useAuthClient();

  useEffect(() => {
    const testAuth = async () => {
      if (auth.isAuthenticated) {
        try {
          const response = await authClient.get('/v1/users/me');
          console.log('Authenticated request result:', response.data);
        } catch (error) {
          console.error('Auth test failed:', error);
        }
      }
    };

    testAuth();
  }, [auth.isAuthenticated, authClient]);

  return (
    <Container maxW="md" py={12} mt="20vh">
      <Box
        bg="yellow.50"
        p={8}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="orange.300"
      >
        <VStack spacing={6}>
          <Heading color="orange.600">Sign in to Draff</Heading>
          <Text color="gray.600">Enter your credentials to continue</Text>

          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              placeholder="coolcoder123"
              bg="white"
              borderColor="orange.300"
              _hover={{ borderColor: 'orange.400' }}
              _focus={{ borderColor: 'orange.500', boxShadow: 'outline' }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="••••••••"
              bg="white"
              borderColor="orange.300"
              _hover={{ borderColor: 'orange.400' }}
              _focus={{ borderColor: 'orange.500', boxShadow: 'outline' }}
            />
          </FormControl>

          <Button
            w="full"
            bgColor="orange.600"
            color="white"
            _hover={{ bgColor: 'orange.700' }}
            onClick={() => auth.signinRedirect()}
          >
            Sign In
          </Button>
        </VStack>
      </Box>
    </Container>
  );
};

export default Signin;
