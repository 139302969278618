import { apiClient } from '../services/api';
import { useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

interface UseGetCodeParams {
  username: string;
  title: string;
}

/** Gets the draff code for a given username and title.
 * Will use auth token if available but will try if not logged in
 * (since draffs can have public access)
 */
export const useGetCode = ({ username, title }: UseGetCodeParams) => {
  const [code, setCode] = useState(' ');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    const fetchCode = async () => {
      if (error || !loading) {
        return;
      }
      if (username === 'dev/null') {
        setLoading(false);
        setError('');
        setCode('// Your code here...');
        return;
      }
      if (title === undefined) {
        window.location.href = '/js/new';
        return;
      }

      try {
        const response = await apiClient.get(
          `/v1/draffs/${username}/${title}`,
          {
            headers: {
              ...(auth.user?.access_token
                ? { Authorization: `Bearer ${auth.user?.access_token}` }
                : {}),
            },
          },
        );

        setCode(response.data.code);
        setLoading(false);
      } catch (err: any) {
        if (err.response?.status === 404) {
          setError('Not Found!');
        } else {
          setError('An error occurred');
          console.error('Error fetching code:', err);
        }
        setLoading(false);
      }
    };

    fetchCode();
  }, [username, title, error, loading, auth.user]);

  return { code, error, loading };
};
