import {
  Box,
  Code,
  Flex,
  Highlight,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  useToast,
} from '@chakra-ui/react';
import { javascript } from '@codemirror/lang-javascript';
import { Prec } from '@codemirror/state';
import { keymap } from '@codemirror/view';
import { EditorView, basicSetup } from 'codemirror';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '../../components/Editor';
import { Terminal } from '../../components/Terminal';
import { CallbackFn, ConsoleFn, safeEval } from '../../util/safeEval';
import {
  MetaSyntox,
  asLogLevel,
  asPlainText,
  syntaxify,
} from '../../util/syntaxify';
import { DraffNotFoundError } from './DraffNotFoundError';
import { DraffError } from './DraffError';
import { EditorButtons } from './EditorButtons';
import { TerminalButtons } from './TerminalButtons';
import { apiClient } from '../../services/api';
import { UsernameSetupModal } from '../../components/UsernameSetupModal';
import { useAuthContext } from '../../contexts/AuthContext';
import { useDraffContext } from '../../contexts/DraffContext';
import { useSaveCode } from '../../api/useSaveCode';
import { Navbar } from '../../ui/Navbar';

const defaultLines = [
  ...`       ,"-.
       ||~'    Draff JS REPL v00.7 (incomplete)
    ___||         copyright (c) 2024 draff.io
   ,(.:')
    || ||
    ^^ ^^
    `
    .split('\n')
    .map((line) => syntaxify(asPlainText(line))),
];
const timeouts: Record<number, number> = {};

const sanitizeTitle = (input: string) => {
  const sanitized = input.replace(/[^a-zA-Z0-9._-]/g, '');
  return sanitized.slice(0, 65);
};

export const Draff = () => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [termLines, setTermLines] = useState(defaultLines);
  const [editor, setEditor] = useState<EditorView | null>(null);
  const [, setCurrentCode] = useState<string>('');
  const { isAuthenticated, backendUser, user } = useAuthContext();
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [usernameSuggestion, setUsernameSuggestion] = useState('');
  const [shareUrl, setShareUrl] = useState(window.location.href);
  const toast = useToast();
  const { error: saveCodeError } = useSaveCode();
  const {
    initialCode,
    error,
    isLoading: loading,
    username,
    title,
    saveDraff,
    isSaving,
    renameDraff,
  } = useDraffContext();

  useEffect(() => {
    const checkUser = async () => {
      if (isAuthenticated && user?.access_token) {
        try {
          const response = await apiClient.get('/v1/users/check', {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });

          if (!response.data.exists) {
            setUsernameSuggestion(response.data.suggestion);
            setShowUsernameModal(true);
          }
        } catch (error) {
          console.error('Failed to check user existence:', error);
        }
      }
    };
    checkUser();
  }, [isAuthenticated, user?.access_token]);

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === 403 &&
          isAuthenticated &&
          user?.access_token
        ) {
          const checkUser = async () => {
            try {
              const response = await apiClient.get('/v1/users/check', {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                },
              });
              if (response.data.suggestion) {
                setUsernameSuggestion(response.data.suggestion);
              }
            } catch (err) {
              console.error('Failed to get username suggestion:', err);
            }
          };
          checkUser();
          setShowUsernameModal(true);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      apiClient.interceptors.response.eject(interceptor);
    };
  }, [isAuthenticated, user?.access_token]);

  useEffect(() => {
    if (saveCodeError?.toast) {
      toast({
        title: saveCodeError.toast,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  }, [saveCodeError, toast]);

  useEffect(() => {
    if (!editor || !initialCode) {
      return;
    }
    editor.dispatch({
      changes: {
        from: 0,
        to: editor.state.doc.length,
        insert: initialCode,
      },
    });
    setCurrentCode(initialCode);
  }, [editor, initialCode]);

  useEffect(() => {
    if (!editorRef?.current || error) {
      return;
    }
    const editorView = new EditorView({
      doc: '',
      extensions: [
        basicSetup,
        javascript(),
        Prec.highest(
          keymap.of([
            {
              key: 'Ctrl-Enter',
              mac: 'Cmd-Enter',
              run: (view) => {
                const code = view.state.doc.toString();
                onExecute(code, true);
                return true;
              },
            },
          ]),
        ),
      ],
      parent: editorRef.current,
    });
    setEditor(editorView);
    return () => editorView.destroy();
  }, [editorRef, error]);

  const onExecute = async (
    code: string,
    clearScope?: boolean,
    voidRun?: boolean,
  ) => {
    if (clearScope) {
      setTermLines([]);
    }
    const logLines: React.JSX.Element[] = [];
    const consoleFn: ConsoleFn = (level: any, args: any[]) => {
      logLines.push(syntaxify(asLogLevel(args[0], level)));
    };
    const callbackFn: CallbackFn = (type, callback, interval) => {
      if (type === 'timeout') {
        setTimeout(() => {
          onExecute(`(${callback})()`, false);
        }, interval);
      }
    };
    const output = await safeEval(code, {
      consoleFn,
      callbackFn,
      clearHistory: clearScope,
    });
    setTermLines([...logLines, syntaxify(output)]);
  };

  const onNewTermLines = (lines: string[]) =>
    setTermLines([...termLines, ...lines.map((line) => syntaxify(line))]);

  const onTermClear = () => setTermLines([]);
  const onTermConsole = (arg: any, level: MetaSyntox['level']) => {
    setTermLines([]);
  };

  const onRun = () => {
    if (!editor || loading) {
      return null;
    }
    const code = editor.state.doc.toString();
    onExecute(code, true);
  };

  const onSave = async () => {
    if (!editor || loading) {
      return;
    }
    const code = editor.state.doc.toString();
    await saveDraff(code, { updateUrl: true });
  };

  const [editableTitle, setEditableTitle] = useState(title);

  useEffect(() => {
    setEditableTitle(title);
  }, [title]);

  const onTitleChange = async (newTitle: string) => {
    if (!isAuthenticated || !backendUser || username === 'dev/null') {
      return;
    }

    try {
      await renameDraff(newTitle);
    } catch (error: any) {
      console.error('Failed to rename:', error);
      toast({
        title: 'Failed to rename Draff',
        description: error.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setEditableTitle(title);
    }
  };

  const handleUsernameSet = async (newUsername: string) => {
    if (!isAuthenticated || !user?.access_token) return;

    try {
      await apiClient.post(
        '/v1/users',
        { username: newUsername },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      setShowUsernameModal(false);
      window.location.reload();
    } catch (error) {
      console.error('Failed to set username:', error);
    }
  };

  return (
    <Box maxHeight="100vh" bgColor="yellow.50">
      <UsernameSetupModal
        isOpen={showUsernameModal}
        onClose={() => setShowUsernameModal(false)}
        onSuccess={handleUsernameSet}
        initialSuggestion={usernameSuggestion}
      />
      <Navbar
        title={
          <Code background="transparent" p={4} fontSize="17px">
            <Text as="span" color="orange.600" fontWeight="bold">
              {username !== 'dev/null'
                ? `${username.startsWith('@') ? '' : '@'}${username}`
                : username}
            </Text>
            {/* // TODO we do need to abstract saving to DraffContext, and navbar
            pulls state (user name and conditionall registering Editbble) from
            there.*/}
            {window.location.pathname.match(/^\/d\/@[^/]+\/.+/) ? (
              <>
                <Text as="span" fontWeight="bold" color="yellow.800">
                  /
                </Text>
                <Editable
                  value={editableTitle}
                  onChange={(value) => setEditableTitle(sanitizeTitle(value))}
                  onSubmit={onTitleChange}
                  onCancel={() => setEditableTitle(title)}
                  display="inline"
                  color="yellow.800"
                  fontWeight="bold"
                  submitOnBlur={true}
                  startWithEditView={false}
                  _hover={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  <EditablePreview />
                  <EditableInput
                    width="auto"
                    minWidth="100px"
                    background="yellow.50"
                    border="1px"
                    borderColor="orange.300"
                    _focus={{
                      background: 'white',
                      boxShadow: 'outline',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.currentTarget.blur();
                      }
                    }}
                  />
                </Editable>
              </>
            ) : (
              <Text as="span" fontWeight="bold" color="yellow.800">
                /{title}
              </Text>
            )}
            <Box as="span" pl={4} fontSize="17px" display="inline-block">
              {error === 'Not Found!' && (
                <Text as="span" color="white">
                  <Highlight
                    query="404"
                    styles={{
                      px: '2',
                      py: '1',
                      rounded: 'full',
                      bg: 'red.600',
                      color: 'gray.100',
                      fontFamily: 'monospace',
                      fontWeight: 'bold',
                    }}
                  >
                    404
                  </Highlight>
                </Text>
              )}
            </Box>
          </Code>
        }
      />

      <Flex
        height={{ md: '100%' }}
        maxHeight={{ md: '100%' }}
        minHeight={{ base: '75em', md: '100%' }}
        bg="gray.700"
        margin={{ base: '0', md: '0 1em' }}
        marginTop="0"
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex
          direction="column"
          minWidth={{ base: '100%', md: '50%' }}
          bg="yellow.100"
        >
          <EditorButtons
            onRun={onRun}
            onSave={onSave}
            isSaving={isSaving}
            shareUrl={shareUrl}
            disable={loading || !!error || !isAuthenticated}
            disableReason={!isAuthenticated ? 'Login first' : undefined}
            isAuthenticated={isAuthenticated}
          />
          <Box bg="yellow.100" maxH={{ base: '60vh', md: '100%' }}>
            {error === 'Not Found!' && <DraffNotFoundError />}
            {error && error !== 'Not Found!' && <DraffError message={error} />}

            {!error && <Editor editorRef={editorRef} />}
          </Box>
        </Flex>
        <Flex
          direction="column"
          minH={{ base: '8em' }}
          minWidth={{ base: '100%', md: '50%' }}
          bgColor="gray.700"
        >
          <TerminalButtons onClear={onTermClear} />
          <Terminal
            lines={termLines}
            onNewLines={onNewTermLines}
            onClear={onTermClear}
            onConsole={onTermConsole}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
