import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  VStack,
  useToast,
  Link,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { apiClient } from '../services/api';
import { useAuth } from 'react-oidc-context';
interface UsernameSetupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (username: string) => void;
  initialSuggestion?: string;
}

export const UsernameSetupModal = ({
  isOpen,
  onClose,
  onSuccess,
  initialSuggestion,
}: UsernameSetupModalProps) => {
  const [username, setUsername] = useState(initialSuggestion || '');
  const [error, setError] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const toast = useToast();
  const auth = useAuth();
  useEffect(() => {
    if (initialSuggestion) {
      setUsername(initialSuggestion);
      setSuggestion(initialSuggestion);
      setError('');
    }
  }, [initialSuggestion]);

  useEffect(() => {
    if (!isOpen) {
      setError('');
      setSuggestion('');
      setIsChecking(false);
    }
  }, [isOpen]);

  if (!auth.user) {
    return null;
  }
  const validateUsername = (value: string) => {
    if (value.length < 3) {
      return 'Username must be at least 3 characters long';
    }
    if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
      return 'Username can only contain letters, numbers, underscores, and hyphens';
    }
    return '';
  };

  const checkUsername = async (value: string) => {
    setIsChecking(true);
    setError('');
    setSuggestion('');

    try {
      await apiClient.get(`/v1/username/available/${value}`, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
        },
      });
      return true;
    } catch (err: any) {
      if (err.response?.status === 409) {
        setError('Too slow! Username taken...');
      } else if (err.response?.status === 400) {
        setError(err.response.data.message);
      } else {
        setError('Failed to check username availability');
      }
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  const handleSetUsernameSubmit = async () => {
    const validationError = validateUsername(username);
    if (validationError) {
      setError(validationError);
      return;
    }

    const isAvailable = await checkUsername(username);
    if (!isAvailable) {
      return;
    }

    try {
      await apiClient.post(
        '/v1/users',
        { username },
        {
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );

      onSuccess(username);
      onClose();

      toast({
        title: 'Welcome to Draff!',
        description: `Your username has been set to @${username}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setError('Failed to create user. Please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="yellow.50" borderColor="orange.300" borderWidth="1px">
        <ModalHeader color="orange.600">Welcome to Draff!</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Text>
              Choose a username to get started. This will be your unique
              identifier for sharing your code.
            </Text>
            <FormControl isInvalid={!!error}>
              <FormLabel>Username</FormLabel>
              <Input
                placeholder="coolcoder123"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setError('');
                  setSuggestion('');
                }}
                bg="white"
                borderColor="orange.300"
                _hover={{ borderColor: 'orange.400' }}
                _focus={{ borderColor: 'orange.500', boxShadow: 'outline' }}
              />
              <FormErrorMessage fontWeight="bold" color="red.500">
                {error}
              </FormErrorMessage>
              {suggestion && !error && (
                <Text mt={2} color="orange.600" fontSize="sm">
                  We found an available username for you!
                </Text>
              )}
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSetUsernameSubmit}
            isLoading={isChecking}
            bgColor="orange.600"
            color="white"
            _hover={{ bgColor: 'orange.700' }}
            disabled={!username || !!error}
          >
            Set Username
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
