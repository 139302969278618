import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import { Navbar } from '../../ui/Navbar';
import { apiClient } from '../../services/api';

interface BackendUser {
  username: string;
  userId: string;
}

export const Settings = () => {
  const auth = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [backendUser, setBackendUser] = useState<BackendUser | null>(null);

  // Fetch backend user data when we have an access token
  useEffect(() => {
    const fetchUser = async () => {
      if (auth.user?.access_token) {
        try {
          const response = await apiClient.get('/v1/user/self', {
            headers: {
              Authorization: `Bearer ${auth.user.access_token}`,
            },
          });
          setBackendUser(response.data);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      }
    };

    fetchUser();
  }, [auth.user?.access_token]);

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);

      // Make the delete call to the backend
      await apiClient.delete('/v1/account/self', {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      // Log out and redirect to home
      await auth.removeUser();
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting account:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete account. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    } finally {
      await auth.removeUser();
      await auth.signoutSilent();
      window.sessionStorage.clear();
      window.localStorage.clear();
      setIsDeleting(false);
      window.location.href = '/';
      onClose();
    }
  };

  return (
    <Box minHeight="100vh" bgColor="yellow.50">
      <Navbar title="Settings" />

      <Box maxW="container.sm" mx="auto" p={8}>
        <VStack spacing={6} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" color="orange.600" mb={4}>
            Account Settings
          </Text>

          <FormControl>
            <FormLabel color="gray.700">Email</FormLabel>
            <Input
              value={auth.user?.profile.email || ''}
              isDisabled
              bg="white"
              borderColor="orange.300"
              _disabled={{
                opacity: 0.7,
                cursor: 'not-allowed',
              }}
            />
            <Text fontSize="sm" color="gray.500" mt={1}>
              Email cannot be changed at this time
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel color="gray.700">Username</FormLabel>
            <Input
              value={backendUser?.username || ''}
              isDisabled
              bg="white"
              borderColor="orange.300"
              _disabled={{
                opacity: 0.7,
                cursor: 'not-allowed',
              }}
            />
            <Text fontSize="sm" color="gray.500" mt={1}>
              Username cannot be changed at this time
            </Text>
          </FormControl>

          <Box pt={6}>
            <Button
              onClick={onOpen}
              colorScheme="red"
              variant="solid"
              size="md"
              borderRadius="0"
              isLoading={isDeleting}
            >
              Delete Account
            </Button>
          </Box>
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="yellow.50" borderColor="orange.300" borderWidth="1px">
          <ModalHeader color="red.500">Confirm Dangerous Action!</ModalHeader>
          <ModalBody>
            <Text>
              This action{' '}
              <strong>
                permanently deletes your account and all Draffs you've created
              </strong>
              . This action cannot be undone. Are you sure?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} borderRadius="0">
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteAccount}
              borderRadius="0"
              isLoading={isDeleting}
            >
              Delete Account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
