import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

interface NavbarProps {
  title: string | ReactNode;
}

export const Navbar = ({ title }: NavbarProps) => {
  const { isAuthenticated, user, backendUser, signout, signin } =
    useAuthContext();

  return (
    <Flex
      bgColor="orange.200"
      borderBottomColor="orange.300"
      borderBottomWidth="1px"
      verticalAlign="center"
      maxH="4em"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Link as={RouterLink} to="/">
          <Tooltip label="Giraffe icons created by Freepik - Flaticon">
            <Image
              src={process.env.PUBLIC_URL + '/draff-logo.webp'}
              height="2.8em"
              marginTop={2}
              marginLeft="1em"
            />
          </Tooltip>
        </Link>
        {typeof title === 'string' ? (
          <Text
            as="span"
            fontWeight="bold"
            color="yellow.800"
            fontSize="17px"
            marginLeft="1em"
          >
            {title}
          </Text>
        ) : (
          title
        )}
      </Flex>

      {isAuthenticated ? (
        <Menu>
          <MenuButton>
            <Avatar
              size="sm"
              mr={4}
              cursor="pointer"
              borderRadius="full"
              border="2px solid"
              borderColor="orange.300"
              width="32px"
              height="32px"
            />
          </MenuButton>
          <MenuList
            bg="yellow.50"
            borderColor="orange.300"
            boxShadow="md"
            zIndex={2}
          >
            <MenuItem py={3} px={4} _hover={{ bg: 'yellow.100' }}>
              <Box>
                <Text fontWeight="bold" color="orange.600">
                  {user?.email}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {backendUser?.username ?? 'Anonymous Giraffe'}
                </Text>
              </Box>
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to="/dashboard"
              py={2}
              px={4}
              _hover={{ bg: 'yellow.100' }}
              color="orange.600"
            >
              Dashboard
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to="/settings"
              py={2}
              px={4}
              _hover={{ bg: 'yellow.100' }}
              color="orange.600"
            >
              Settings
            </MenuItem>
            <MenuItem
              onClick={signout}
              py={2}
              px={4}
              _hover={{ bg: 'yellow.100' }}
              color="orange.600"
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button
          onClick={signin}
          bgColor="orange.600"
          color="white"
          _hover={{ bgColor: 'orange.700' }}
          mr={4}
          h="32px"
          borderRadius="0"
          fontSize="14px"
        >
          Log In
        </Button>
      )}
    </Flex>
  );
};
